import React from "react"
import { graphql } from "gatsby"
import { withTheme, ThemeProps } from "styled-components"
import {
  Helmet,
  Banner,
  Layout,
  Theme,
  ReadMoreLink,
  Interfaces,
  Container,
  Row,
  Box,
  Heading,
  routesObject
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"

import ContactUsSection from "../../../components/services/aged-care/ContactUsSection"
import { AgedCareForm } from "@life-without-barriers/shared-contact-form"

const { agedCareThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const PageForm = (
  <AgedCareForm
    formId="desktop-chsp-hcp"
    enquiryType="CHSP and Home Care Packages"
  />
)

export default withTheme(
  ({
    location,
    data: {
      site: { siteMetadata },
      bannerImage
    }
  }: Props & ThemeProps<Interfaces.Theme>) => {
    return (
      <Layout
        theme={agedCareThemeFull}
        fixedCTAPhoneNumber="1800792359"
        fixedCTAContact={PageForm}
      >
        <div>
          <Helmet
            title={`CHSP and Home Care Packages | ${siteMetadata.title}`}
            description="Home Care Packages and the Commonwealth Home Support Programme (CHSP) both give you support to stay at home, but there are differences between the programs."
            image={bannerImage}
            siteUrl={siteMetadata.siteUrl}
            path={location.pathname}
            themeColor={agedCareThemeFull.medium}
          />
          <Banner
            title="CHSP and Home Care Packages"
            image={bannerImage}
            breadcrumbs={[
              routesObject.home,
              routesObject.service,
              {
                to: "/services/aged-care/",
                text: "Aged care services",
                title: "Aged care services"
              }
            ]}
          />
          <Container>
            <Row className="flex-wrap relative">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>
                  What is the difference between CHSP and Home Care Packages?
                </Heading>
                <p>
                  Home Care Packages and the Commonwealth Home Support Programme
                  (CHSP) both give you support to stay at home, but there are
                  differences between the programs.
                </p>
                <div className="mt4 copy flex-ns">
                  <div className="pr4-ns">
                    <h2 className="fw8 ts-display-4">With CHSP, you:</h2>
                    <ul className="ma0 mt3 pl3">
                      <li className="mb2">
                        Choose from a set menu of services with only a small
                        amount of flexibility
                      </li>
                      <li className="mb2">Only pay for the services you use</li>
                      <li className="mb2">
                        Your eligibility is assessed by the Regional Assessment
                        Service (RAS) through My Aged Care
                      </li>
                      <li className="mb2">
                        Entry level support only for those that require a small
                        amount of support
                      </li>
                      <li>
                        Choice of providers is limited based on availability of
                        funding
                      </li>
                    </ul>
                    <ReadMoreLink
                      className="mt3 mt4-ns"
                      href="/services/aged-care/commonwealth-home-support-program/"
                      title="Learn more about CHSP"
                      text="Learn more"
                    />
                  </div>
                  <div className="pl3-ns mt4 mt0-ns">
                    <h2 className="fw8 ts-display-4">
                      With a Home Care Package:
                    </h2>
                    <ul className="ma0 mt3 pl3">
                      <li className="mb2">
                        You are in control of your package funds and have the
                        flexibility to choose the supports you want
                      </li>
                      <li className="mb2">
                        Once you are assigned a package, it stays in place until
                        you no longer need it
                      </li>
                      <li className="mb2">
                        Your eligibility is assessed by the Aged Care Assessment
                        Team (ACAT) through My Aged Care
                      </li>
                      <li>
                        Case Manager assigned to you to assist with finding the
                        supports you need and want
                      </li>
                    </ul>
                    <ReadMoreLink
                      className="mt3 mt4-ns"
                      href="/services/aged-care/home-care-packages/"
                      title="Learn more about Home Care Packages"
                      text="Learn more"
                    />
                  </div>
                </div>
              </Box>
              <Box className="dn db-l w-100 w-third-l mt5 mt0-l absolute-l top-0-l right-0-l">
                {PageForm}
              </Box>
            </Row>
          </Container>
          <ContactUsSection
            title="Getting Started"
            formId="mobile-chsp-hcp"
            formTitle="Talk to a specialist"
            contactMethod="phone"
            enquiryType="CHSP and Home Care Packages"
            hideMyAgedCareField={false}
          />
        </div>
      </Layout>
    )
  }
)

export const query = graphql`
  {
    bannerImage: file(relativePath: { regex: "/img-chsp-hcp.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 90)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
